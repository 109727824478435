import React from 'react';
import './index.css';
import '../../showcase.css';
import classNames from "classnames";
import HeroImage from "../../../hero-img";
import {ShowcaseInfo} from "../../type";
import Header from "../../../header";
import Footer from "../../../footer";
import Nav from "../../nav";

interface ProjectTemplateProps {
  info: ShowcaseInfo;
  className?: string;
}

const ProjectTemplate: React.FC<ProjectTemplateProps> = props => {
  return (
    <div className={classNames("showcase project", props.className)}>
      <Header />
      <Nav info={props.info}/>
      <article>
        <div className={"title-wrapper"}>
          <h1 className={"title"}>{props.info.title}</h1>
          <div className={"hero-wrapper"}>
            <HeroImage src={props.info.heroImage} />
          </div>
        </div>
        <div>
          {props.children}
        </div>
      </article>
      <Footer />
    </div>
  );
}

export default ProjectTemplate;
