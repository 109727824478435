import React from 'react';
import './index.css';
import {ReactComponent as Close} from "./close.svg";
import classNames from "classnames";
import LinkContext from './link-context';
import Link from "./link";
import Directory from "../../directory";
import {randomColor} from "../colors";
import HeroImage from "../hero-img";
import { useModalScrollLock } from '../../hooks/modal';

interface MenuProps {
  isOpen: boolean;
  onClose(): void;
}

const Menu: React.FC<MenuProps> = props => {
  useModalScrollLock(props.isOpen);

  const [glowColor, setGlowColor] = React.useState('white');
  const [hoveredLink, setHoveredLink] = React.useState<string | null>(null);

  const [hoveredProject] = Directory.all().find(([p]) => p.slug === hoveredLink) ?? [];

  return (
    <LinkContext.Provider value={{ glowColor, hoveredLink, hoverOverLink: (slug) => {
        setGlowColor(randomColor()!);
        setHoveredLink(slug);
      } }}>
      <div className={classNames("menu", props.isOpen && "open")}>
        <div className={"shade"} onClick={props.onClose} />
        <div className={"menu-wrapper"}>
          <div className={"project-spotlight"}>
            {/*<h1 style={{ color: 'white' }}>{hoveredLink}</h1>*/}
            { hoveredProject && <HeroImage src={hoveredProject?.heroImage} /> }
          </div>
          <div className={"menu-content"}>
            <div className={"glow"} style={{
              backgroundImage: `linear-gradient(270deg, ${glowColor} 1%, rgba(0, 0, 0, 0) 100%)`
            }} />
            <div className={"glow-hoverout"} onMouseEnter={() => setGlowColor("white")} />
            <Close className="close-icon" onClick={props.onClose} />
            <div className={"links"}>
              <label>about</label>
              <div className="link-list">
                <Link to={"/about"}>About</Link>
                <Link newTab={"https://drive.google.com/file/d/18pbVqumz_ozdSw5x4a1G1i23LU-WflmQ/view?usp=sharing"}>Resume</Link>
              </div>
              <label>select projects</label>
              <div className="link-list">
                {Directory.projects.map(([project]) => (
                  <Link key={project.slug} to={project.slug}>{project.title}</Link>
                ))}
              </div>
              <label>collections</label>
              <div className="link-list">
                {Directory.collections.map(([collection]) => (
                  <Link key={collection.slug} to={collection.slug}>{collection.title}</Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LinkContext.Provider>
  );
}

export default Menu;
