import React from 'react';
import "./index.css"; 

const Footer: React.FC = () => {
  return (
    <footer>
        <div className={"copyright"}>©2021</div>
    </footer>
  );
};

export default Footer;