import React from 'react';
import classNames from "classnames";

const NotFoundPage: React.FC = props => {
  return (
    <div>
      NOT FOUND
    </div>
  );
}

export default NotFoundPage;
