import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import AboutPage from './pages/about';
import HomePage from "./pages/home";
import NotFoundPage from './pages/not-found';
import Directory from "./directory";

const App: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route exact path="/about">
          <AboutPage />
        </Route>
        {Directory.projects.map(([info, component]) => (
          <Route key={info.slug} path={info.slug} component={component}/>
        ))}
        {Directory.collections.map(([info, component]) => (
          <Route key={info.slug} path={info.slug} component={component}/>
        ))}
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  );
}

export default App;

const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}