import React from 'react';
import ProjectTemplate from "../../../components/showcase/project/template";
import {ShowcaseInfo} from "../../../components/showcase/type";
import ShowcaseDescription from "../../../components/showcase/description";
import { HOSTED_FILE_ROOT } from '../../../files';

const CONTRASTVR_FILE_ROOT = HOSTED_FILE_ROOT + "/contrastvr";

export const ContrastVRInfo: ShowcaseInfo = {
  title: "ContrastVR",
  heroImage: CONTRASTVR_FILE_ROOT + "/hero.jpg",
  slug: '/project/contrastvr',
  date: 'Fall 2020 – present'
}

const ContrastVRPage: React.FC = props => {
  return (
    <ProjectTemplate info={ContrastVRInfo} className={"contrastvr"}>
      <ShowcaseDescription 
        date={ContrastVRInfo.date}
        subtitle={"ContrastVR is enabling people with light perception vision to perceive virtual environments."}
        info={[{
            title: "Skills",
            items: ["Research", "Usability", "VR Dev"]
          }, {
            title: "Tools",
            items: ["Unreal Engine", "Varjo"]
          }, {
            title: "Team",
            items: ["Teo Boley", "Casper Harteveld"]
          }]}
      >
        <div>
          Current research in vision accessibility for virtual reality does not explore the unique means of interaction
          afforded by light perception vision (i.e., blindness with only remaining light perception). We present
          ContrastVR, a high-contrast filter designed to optimize virtual environments for people with light perception
          vision. A user can customize their view by adjusting levels of brightness and detail of the overall
          environment, as well as the shininess and gamma of textures. They can also choose between outline and planar
          models of the environment, and toggle an orientation lightsource and depth map. ContrastVR also dynamically
          reduces visual noise by adjusting levels of detail based on the relative distance of environment elements.
          <br/><br/>
          With help from The Carroll Center for the Blind, we are planning to evaluate how participants with light
          perception vision perceive, play, and enjoy virtual reality environments with ContrastVR.
        </div>
      </ShowcaseDescription>
      <section>
          <div>
            <img
              className={"col-span-2"}
              src={CONTRASTVR_FILE_ROOT + "/stills.jpg"}
              alt={"Four rooms with the ContrastVR filter applied."}
            />
          </div>
      </section>
    </ProjectTemplate>
  );
}

export default ContrastVRPage;
