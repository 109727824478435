import React from "react";
import Menu from "../menu";
import {Link} from "react-router-dom";
import classNames from "classnames";
import {ReactComponent as MenuIcon} from "../menu/menu.svg";
import "./index.css";
import {randomColor} from "../colors";

const Header: React.FC = props => {
  const [hovered, setHovered] = React.useState(false);

  const [glowColor, setGlowColor] = React.useState('white');
  const setRandomGlowColor = () => setGlowColor(randomColor()!)

  const [menuIsOpen, setMenuIsOpen] = React.useState(false);
  const [bgActive, setBgActive] = React.useState(false);

  React.useEffect(() => {
    const listener = () => {
      setBgActive(window.scrollY / 100 >= 1)
    }

    window.addEventListener("scroll", listener);

    return () => {
      window.removeEventListener("scroll", listener);
    }
  }, [])

  return (
    <>
      <Menu isOpen={menuIsOpen} onClose={() => setMenuIsOpen(false)} />
      <header className={classNames("pointer-events-none", bgActive ? "bg-active" : "")}>
        <Link to="/">
          <h1
            onMouseEnter={() => {
              setHovered(true);
              setRandomGlowColor();
            }}
           onMouseLeave={() => setHovered(false)}
           style={{
             textShadow: `0 0 30px ${hovered ? glowColor : 'transparent'}, 0 0 15px ${hovered ? glowColor : 'transparent'}`
           }} className={"pointer-events-auto"}>
            Nico
          </h1>
        </Link>
        <div className={classNames("pointer-events-auto", menuIsOpen && "menu-open")} onClick={() => setMenuIsOpen(true)}><MenuIcon /></div>
      </header>
    </>
  );
};

export default Header;
