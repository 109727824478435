import React from 'react';
import ProjectTemplate from "../../../components/showcase/project/template";
import ShowcaseDescription from "../../../components/showcase/description";
import {ShowcaseInfo} from "../../../components/showcase/type";
import PdfImage from '../../../components/pdf-img';
import { HOSTED_FILE_ROOT } from '../../../files';

const VIO_FILE_ROOT = HOSTED_FILE_ROOT + "/vio";

export const VioInfo: ShowcaseInfo = {
  title: "Vio Financial",
  heroImage: VIO_FILE_ROOT + "/hero.jpg",
  slug: '/project/vio',
  date: 'Fall 2020'
}

const VioPage: React.FC = props => {
  return (
    <ProjectTemplate info={VioInfo}>
      <ShowcaseDescription 
        date={VioInfo.date}
        subtitle={"Vio is an empathic financial planning chatbot for young adults."}
        info={[{
            title: "Skills",
            items: ["Prototyping", "Research", "UI/UX"]
          }, {
            title: "Tools",
            items: ["Figma", "Otter.ai", "Zoom"]
          }, {
            title: "Team",
            items: ["Dasha Alekseeva", "Rebecca Anderson", "Sumin Lim"]
          }]}
      >
        <div>
          Reaching financial goals requires careful planning and strategy. It is even more challenging when you are a
          young adult entering the world of finance with insufficient understanding of financial terms, tools, and
          mechanisms.
          <br/><br/>
          The goal of this project was to generate an intervention that can improve how young adults reach their
          financial goals and learn about personal finances. Through robust qualitative analysis, we uncovered how young
          adults approach finances, conceptualized a product that would make personal financial planning accessible and
          stress-free, and validated our intervention.
          <br/><br/>
          Our ultimate solution was Vio, a financial planning platform centered around an empathetic chatbot.
        </div>
      </ShowcaseDescription>
      <section>
        <h3>Process Book</h3>
        <div className={"grid grid-cols-2 gap-2 lg:gap-4"}>
          <PdfImage
              className={"col-span-2"}
              pdfSlug={"Vio Process Book"}
              src={VIO_FILE_ROOT + "/process_book.jpg"}
              alt={"Title slide of process book."}
          />
          <img
            className={"col-span-1"}
            src={VIO_FILE_ROOT + "/mock_render_1.jpg"}
            alt={"iPhone render featuring a goal page for an emergency fund."}
          />
          <img
            className={"col-span-1"}
            src={VIO_FILE_ROOT + "/mock_render_2.jpg"}
            alt={"iPhone render featuring a chatbot exchange adjusting the user's monthly budget to afford a new jacket."}
          />
        </div>
      </section>
    </ProjectTemplate>
  );
}

export default VioPage;
