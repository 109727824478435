import React from 'react';
import InfoCallout from '../../../components/info-callout';
import PdfImage from '../../../components/pdf-img';
import CollectionItems from '../../../components/showcase/collection/items';
import CollectionTemplate from '../../../components/showcase/collection/template';
import ShowcaseDescription from '../../../components/showcase/description';
import {ShowcaseInfo} from "../../../components/showcase/type";
import { AstroHUDInfo } from '../../project/astrohud';
import { ContrastVRInfo } from '../../project/contrastvr';
import { HOSTED_FILE_ROOT } from '../../../files';

const INTERACTION_DESIGN_FILE_ROOT = HOSTED_FILE_ROOT + "/interaction_design";

export const InteractionDesignInfo: ShowcaseInfo = {
  title: "Interaction Design",
  heroImage: INTERACTION_DESIGN_FILE_ROOT + "/hero.jpg",
  slug: '/collection/interaction-design'
}

const InteractionDesignPage: React.FC = props => {
  return (
    <CollectionTemplate info={InteractionDesignInfo}>
      <ShowcaseDescription
        subtitle={"Designing interactive experiences across devices."}
        info={[{
            title: "Skills",
            items: ["Interaction Design", "Prototyping", "UI/UX Design"]
          }]}
        hideInfoOnMobile
      >
        This collection features select projects created across my interaction design concentration. 
        It includes work from my degree capstone and independent study; mobile app redesigns; and 
        virtual reality experiences.
      </ShowcaseDescription>
      <section>
          <CollectionItems>
            {[
              {
                link: AstroHUDInfo
              },
              {
                link: ContrastVRInfo
              },
              {
                date: "Fall 2019",
                title: "Postmates Redesign",
                body: (<>
                  <div className={"flex flex-col md:flex-row md:justify-between"}>
                    <p className={"md:mr-28 lg:mr-44 ml-gutter"}>
                      This redesign sought to improve the then-frustrating experience of ordering food using the Postmates 
                      mobile app. To identify pain points, I performed a robust mapping of the interaction flow. Then, I 
                      created a new flow and made updates to the interface design to facilitate smoother and more informed 
                      ordering.
                    </p>
                    <InfoCallout label={"Tools"} className={"mt-6 md:mt-0"}>
                      <div>Sketch</div>
                    </InfoCallout>
                  </div>
                  <PdfImage pdfSlug={"Postmates Redesign Process Book"} src={INTERACTION_DESIGN_FILE_ROOT + "/postmates.jpg"} alt={""} className={"mt-14 lg:mt-24"} />
                </>)
              },
              {
                date: "Spring 2018",
                title: "CyberPyramid",
                body: (<>
                  <div className={"flex flex-col md:flex-row md:justify-between"}>
                    <p className={"md:mr-28 lg:mr-44 ml-gutter"}>
                    CyberPyramid is an Egyptian-cyberpunk themed virtual reality escape room. Designed to be a 10 
                    minute experience for inexperience VR users, it features simple color puzzle keys to navigate 
                    between rooms.
                    </p>
                    <InfoCallout label={"Tools"} className={"mt-6 md:mt-0"}>
                      <div>Unreal Engine</div>
                    </InfoCallout>
                  </div>
                  <img src={INTERACTION_DESIGN_FILE_ROOT + "/cyberpyramid.jpg"} alt="Five interior areas of the CyberPyramid." className={"mt-14 lg:mt-24"}/>
                </>)
              }
            ]}
          </CollectionItems>
      </section>
    </CollectionTemplate>
  );
}

export default InteractionDesignPage;
