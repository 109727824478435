import React from 'react';
import ProjectTemplate from "../../../components/showcase/project/template";
import ShowcaseDescription from "../../../components/showcase/description";
import {ShowcaseInfo} from "../../../components/showcase/type";
import { HOSTED_FILE_ROOT } from '../../../files';
import "./index.css";

const WIMBLEDON_FILE_ROOT = HOSTED_FILE_ROOT + "/wimbledon";

export const WimbledonInfo: ShowcaseInfo = {
  title: "Wimbledon",
  heroImage: WIMBLEDON_FILE_ROOT + "/hero.jpg",
  slug: '/project/wimbledon',
  date: 'Fall 2020'
}

const WimbledonPage: React.FC = props => {
  return (
    <ProjectTemplate info={WimbledonInfo} className="wimbledon">
      <ShowcaseDescription 
        date={WimbledonInfo.date}
        subtitle={"Wimbledon is a modern transitional typeface designed for print and web."}
        info={[{
            title: "Skills",
            items: ["Graphic Design", "Typography"]
          }, {
            title: "Tools",
            items: ["Illustrator", "Glyphs"]
          }]}
      >
        <div>
          Legible and beautiful at both text and display sizes, Wimbledon is an exercise in detail and form. Elegant 
          upper case pair with spirited lowercase–for a match starting in love.
          <br/><br/>
          This modern transitional typeface was designed in one weight over twelve weeks. It includes a full latin 
          alphabet, numerals, punctuation, and symbols.
        </div>
      </ShowcaseDescription>
      <section>
        <img
          src={WIMBLEDON_FILE_ROOT + "/grid.png"}
          alt={"Grid of characters in Wimbledon typeface."}
        />
        <div className={"two-columns px-gutter mr-2.5 lg:mr-0 my-14 lg:my-24"}>
          <p className={"inline md:block"}>
            Using Karen Chang’s <u>Designing Type</u> as a reference and guide, I designed my first typeface. 
            This iterative process took place over twelve weeks. I worked in an order increasing 
            in complexity, so that stylistic elements of the typeface could emerge organically.
            <br/><br/>
            <span className={"inline-block md:w-8"}/>
            I first established the control characters, O and H. I developed the remaining capital letterforms 
            in the order of perpendiculars, obliques, and curves. Then, I worked on the lowercase letterforms, 
            lining and oldstyle numerals, and punctuation. I brought the letterforms from Illustrator into Glyphs, 
            and set the kerning and kerning pairs. From this point, I had a functional typeface.
            <br/><br/>
            <span className={"inline-block md:w-8"}/>I implemented minor revisions as inconsistencies were noticed in critique and while typesetting 
            sample text. Unsurprisingly, the most difficult letter to get right was the “s”–it was refined until the very end.
          </p>
        </div>
        <div className={"grid grid-cols-2 gap-2 lg:gap-4"}>
          <img
            className={"col-span-1"}
            src={WIMBLEDON_FILE_ROOT + "/poster_spec.jpg"}
            alt={"Tennis-themed poster of Wimbledon type specimen."}
          />
          <img
            className={"col-span-1"}
            src={WIMBLEDON_FILE_ROOT + "/poster_ad.jpg"}
            alt={"Advertisement poster for fictitious Revington single malt scotch whiskey using Wimbledon typeface."}
          />
          <img
            className={"col-span-2"}
            src={WIMBLEDON_FILE_ROOT + "/sample.png"}
            alt={"Text sample of Wimbledon typeface, featuring five blocks of text in decreasing sizes."}
          />
        </div>
      </section>
    </ProjectTemplate>
  );
}

export default WimbledonPage;
