import classNames from "classnames";
import React, { useState, useMemo, useCallback } from "react";
import { ShowcaseInfo } from "../../type";
import {ReactComponent as LinkIcon} from "./link.svg";
import {ReactComponent as ExpandIcon} from "./expand.svg";
import { Link } from "react-router-dom";
import FeaturedShowcaseTooltip from "../../../featured-showcase-tooltip";

interface CollectionItemProps {
    className?: string;
    content: CollectionItemContent;
}

export type CollectionItemContent = CollectionItemLink | CollectionItemInline

interface CollectionItemLink {
    link: ShowcaseInfo;
}

interface CollectionItemInline {
    date: React.ReactNode;
    title: React.ReactNode;
    body: React.ReactNode;
}

export function isCollectionItemLink(content: any): content is CollectionItemLink {
    return (content as CollectionItemLink).link !== undefined;
}

const CollectionItem: React.FC<CollectionItemProps> = props => {
    const [isOpen, setIsOpen] = useState(false);
    const { content } = props;

    const HeaderWrapper: React.FC<{ className?: string; }> = useCallback(wrapperProps => <div>
        {
            isCollectionItemLink(content) ? 
            <FeaturedShowcaseTooltip info={content.link} followCursor hideLabel><Link to={content.link.slug} className={wrapperProps.className}>{wrapperProps.children}</Link></FeaturedShowcaseTooltip> : 
            <button className={wrapperProps.className} onClick={() => setIsOpen(o => !o)}>{wrapperProps.children}</button>
        }
        </div>
    , [content]);
    
    return (
        <li className={classNames("showcase-item", props.className)}>
            <HeaderWrapper 
                className={"px-0 lg:px-12 py-7 text-left !font-normal flex justify-between items-center w-full"} 
            >
                <div>
                    <div className="text-xs lg:text-tiny mb-0.5 lg:mb-1">{isCollectionItemLink(content) ? content.link.date : content.date}</div>
                    <h4 className="text-base lg:text-med font-display">{isCollectionItemLink(content) ? content.link.title : content.title}</h4>
                </div>
                <div>
                    {isCollectionItemLink(content) ? <LinkIcon /> : <ExpandIcon className={classNames("transform transition-transform", isOpen ? "rotate-45" : "rotate-0")} /> }
                </div>
            </HeaderWrapper>
            { !isCollectionItemLink(content) && <div className={classNames("transition-all duration-700 overflow-y-hidden", isOpen ? "max-h-[1400px]" : "max-h-0")}>
                <div className={"pt-0 lg:pt-5 pb-14 lg:pb-24"}>
                    {content.body}
                </div>
            </div>}
        </li>
    );
}

export default CollectionItem;