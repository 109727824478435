import React from 'react';
import './index.css';
import classNames from "classnames";

interface ShowcaseSubtitleProps {}

const ShowcaseSubtitle: React.FC<ShowcaseSubtitleProps> = props => {
  return (
    <h2 className={classNames("showcase-subtitle")}>
      {props.children}
    </h2>
  );
}

export default ShowcaseSubtitle;
