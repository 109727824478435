/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-undef */

// environment map
// const envMap = ( function () {
//     const path = 'EnvMap/dark-s_';
//     const format = '.jpg';
//     const urls = [
//         path + 'px' + format, path + 'nx' + format,
//         path + 'py' + format, path + 'ny' + format,
//         path + 'pz' + format, path + 'nz' + format
//     ];

// 	const cubeTextureLoader = new THREE.CubeTextureLoader();

//     const reflectionCube = cubeTextureLoader.load( urls );
//     reflectionCube.format = THREE.RGBFormat;

//     return reflectionCube;
// } )();

// material
// material.envMap = envMap;
// material.envMapIntensity = 0.1;

export default class HomeHandsScene {
    handMaterial = new THREE.MeshStandardMaterial({color: 0x000000, roughness: 0.1, metalness: 0.1});

    container;

    camera;
    scene;
    renderer;
    light1; light2; light3; light4;
    light5; light6; light7; light8;
    frontLight; backLight; farBackDirectionalLight; backDirectionalLight; frontDirectionalLight;
    hands = [];
    stats;

    clock = new THREE.Clock();

    disposed = false;

    init(container) {
        console.log("Initializing Scene");
        this.container = container;

        this.camera = new THREE.PerspectiveCamera( 50, this.container.offsetWidth / this.container.offsetHeight, 1, 1000 );
        this.camera.position.z = 100;

        this.scene = new THREE.Scene();
        // scene.background = new THREE.Color( 0x202020 );

        //model

        this.addHands();

        //lights
        this.light1 = new THREE.PointLight( "#D4220A", 2, 120 );
        // light1.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: "#D4220A" } ) ) );
        this.scene.add( this.light1 );

        this.light2 = new THREE.PointLight( "#15A3F7", 2, 120 );
        // light2.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: "#15A3F7" } ) ) );
        this.scene.add( this.light2 );

        this.light3 = new THREE.PointLight( "#14C4A2", 2, 120 );
        // light3.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: "#14C4A2" } ) ) );
        this.scene.add( this.light3 );

        this.light4 = new THREE.PointLight( "#FF8A00", 2, 120 );
        // light4.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: "#FF8A00" } ) ) );
        this.scene.add( this.light4 );

        this.light5 = new THREE.PointLight( "#D4220A", 2, 120 );
        // light5.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: "#D4220A" } ) ) );
        this.scene.add( this.light5 );

        this.light6 = new THREE.PointLight( "#15A3F7", 2, 120 );
        // light6.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: "#15A3F7" } ) ) );
        this.scene.add( this.light6 );

        this.light7 = new THREE.PointLight( "#14C4A2", 2, 120 );
        // light7.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: "#14C4A2" } ) ) );
        this.scene.add( this.light7 );

        this.light8 = new THREE.PointLight( "#FF8A00", 2, 120 );
        // light8.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: "#FF8A00" } ) ) );
        this.scene.add( this.light8 );

        this.frontLight = new THREE.PointLight( "#8B4BF9", 1, 200 );
        this.frontLight.position.x = 100;
        this.frontLight.position.y = -50;
        this.frontLight.position.z = 150;
        // frontLight.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: "#8B4BF9" } ) ) );
        this.scene.add( this.frontLight );

        this.backLight = new THREE.PointLight( "#422CCC", 1, 200 );
        this.backLight.position.x = -150;
        this.backLight.position.y = 50;
        this.backLight.position.z = -150;
        // backLight.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: "#422CCC" } ) ) );
        this.scene.add( this.backLight );

        this.farBackDirectionalLight = new THREE.DirectionalLight( "#0819A0", 5, 150 );
        this.farBackDirectionalLight.position.x = 50;
        this.farBackDirectionalLight.position.y = 300;
        this.farBackDirectionalLight.position.z = -100;
        this.scene.add( this.farBackDirectionalLight );

        this.backDirectionalLight = new THREE.DirectionalLight( "#0819A0", 5 );
        this.backDirectionalLight.position.x = -100;
        this.backDirectionalLight.position.y = 100;
        this.backDirectionalLight.position.z = -150;
        this.scene.add( this.backDirectionalLight );

        this.frontDirectionalLight = new THREE.DirectionalLight( "#FF33FF", 5 );
        this.frontDirectionalLight.position.x = 150;
        this.frontDirectionalLight.position.y = -100;
        this.frontDirectionalLight.position.z = 150;
        this.scene.add( this.frontDirectionalLight );

        //renderer

        this.renderer = new THREE.WebGLRenderer( { antialias: false, powerPreference: "default" } );
        this.renderer.setPixelRatio( window.devicePixelRatio );
        this.renderer.setSize( this.container.offsetWidth, this.container.offsetHeight );
        // renderer.toneMapping = THREE.CineonToneMapping;

        this.container.appendChild( this.renderer.domElement );

        // stats

        if (process.env.NODE_ENV === "development") {
            this.stats = new Stats();
            container.appendChild(this.stats.dom);
        }

        window.addEventListener( 'resize', this.onWindowResize.bind(this), false );

    }

    dispose() {
        this.disposed = true;
        this.renderer.dispose();
        window.removeEventListener( 'resize', this.onWindowResize.bind(this) );
    }

    onWindowResize() {

        this.camera.aspect = this.container.offsetWidth / this.container.offsetHeight;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize( this.container.offsetWidth, this.container.offsetHeight );
    }

    animate() {
        if (!this.disposed) {
            requestAnimationFrame( () => this.animate() );
            if (this.stats) this.stats.begin();
            this.render();
            if (this.stats) this.stats.end();
        }
    }

    render() {
        const time = this.clock.getElapsedTime() / 3;

        for (const hand of this.hands) {
            hand.rotateOnAxis(new THREE.Vector3(0, 1, 0), 0.005);
        }

        this.light1.position.x = Math.sin( time * 0.7 ) * 70; // was 30
        this.light1.position.y = Math.cos( time * 0.5 ) * 40; // was 40
        this.light1.position.z = Math.cos( time * 0.3 ) * 30; // was 30

        this.light2.position.x = Math.cos( time * 0.3 ) * 70; // was 30
        this.light2.position.y = Math.sin( time * 0.5 ) * 40; // was 40
        this.light2.position.z = Math.sin( time * 0.7 ) * 30; // was 30

        this.light3.position.x = Math.sin( time * 0.7 ) * 70; // was 30
        this.light3.position.y = Math.cos( time * 0.3 ) * 40; // was 40
        this.light3.position.z = Math.sin( time * 0.5 ) * 30; // was 30

        this.light4.position.x = Math.sin( time * 0.3 ) * 70; // was 30
        this.light4.position.y = Math.cos( time * 0.7 ) * 40; // was 40
        this.light4.position.z = Math.sin( time * 0.5 ) * 30; // was 30



        this.light5.position.x = Math.cos( time * 0.7 ) * 70; // was 30
        this.light5.position.y = Math.sin( time * 0.5 ) * 40; // was 40
        this.light5.position.z = Math.sin( time * 0.3 ) * 30; // was 30

        this.light6.position.x = Math.sin( time * 0.3 ) * 70; // was 30
        this.light6.position.y = Math.cos( time * 0.5 ) * 40; // was 40
        this.light6.position.z = Math.cos( time * 0.7 ) * 30; // was 30

        this.light7.position.x = Math.cos( time * 0.7 ) * 70; // was 30
        this.light7.position.y = Math.sin( time * 0.3 ) * 40; // was 40
        this.light7.position.z = Math.cos( time * 0.5 ) * 30; // was 30

        this.light8.position.x = Math.cos( time * 0.3 ) * 150; // was 30
        this.light8.position.y = Math.sin( time * 0.7 ) * 40; // was 40
        this.light8.position.z = Math.cos( time * 0.5 ) * 30; // was 30

        this.renderer.render( this.scene, this.camera );
        // stats.update();
    }

    addHands() {
        this.addHand("rock_hand_med",                0,0,60,         0,0,-Math.PI/6); // center
        this.addHand("spock_hand_min",               45,-20,20,      0,0,-Math.PI*4/3); // bottom right
        this.addHand("thumbs_up_hand_min",           -62,-30,0,    0,0,Math.PI/3); // bottom left
        this.addHand("open_hand_min",                65,40,-40,      0,0,-Math.PI/2); // top right
        this.addHand("fingers_crossed_hand_min",     -75,30,-20,       0,0,0); // top left
    }

    addHand(model, x, y, z, rX, rY, rZ) {
        const loader = new THREE.OBJLoader();
        loader.load( '3d-scenes/models/hands/' + model + '.obj', ( obj ) => {
            console.log("Loaded hand");

            obj.scale.multiplyScalar(2.5);
            // obj.scale.multiplyScalar(2.25);
            // obj.scale.multiplyScalar( 0.8 );
            // obj.position.y = - 30;
            obj.position.x = x;
            obj.position.y = y;
            obj.position.z = z;

            obj.rotation.x = rX;
            obj.rotation.y = rY;
            obj.rotation.z = rZ;

            obj.traverse(child => {
                if (child instanceof THREE.Mesh) {
                    child.material = this.handMaterial;
                }
            });

            // obj.rotation.x = Math.PI/2;
            // obj.rotation.y = Math.PI/2;
            // obj.rotation.z = Math.PI/4;

            this.hands.push(obj);
            this.scene.add( obj );
        } );
    }
}
