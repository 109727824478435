import React, { useCallback, useEffect, useRef, useState } from "react";
import Arrow from '../arrow';
import { Document, Page } from 'react-pdf';
import classNames from "classnames";
import "./index.css";
import Modal from "../modal";
import { ReactComponent as ShareSVG } from "./share.svg";
import { HOSTED_FILE_ROOT } from "../../files";

export interface PdfModalProps {
    slug: string;
    isOpen: boolean;
    onClose(): void;
}

const PdfModal: React.FC<PdfModalProps> = props => {
    const { canvasParentRef, canvasRef, pageDefaultDimensionsRef, resizeCanvas } = useResponsiveCanvas();

    const [numPages, setNumPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);

    const close = useCallback(() => {
        props.onClose();
        setNumPages(1);
        setPageNumber(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.onClose]);

    const pdfFile = `${HOSTED_FILE_ROOT}/pdfs/${props.slug}.pdf`;

    return (
        <Modal isOpen={props.isOpen} onClose={close} className="pdf-modal">
            <div className="content-grid">
                <div className="nav-btn-wrapper prev">
                    <button className={classNames(pageNumber === 1 && "disabled")} onClick={() => setPageNumber(pageNumber - 1)}><Arrow left/></button>
                </div>
                <div className="content">
                    <div ref={canvasParentRef} className="canvas-parent">
                        <Document
                            file={pdfFile}
                            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                            className={"document"}
                        >
                            <div className="page-wrapper">
                                <Page pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false} canvasRef={canvasRef} onLoadSuccess={() => {
                                    const canvas = canvasRef.current;
                                    if (canvas) {
                                        const defaultDimensions = { width: canvas.width, height: canvas.height };
                                        console.log(`Default canvas dimensions: (${defaultDimensions.width}px, ${defaultDimensions.height}px)`)
                                        pageDefaultDimensionsRef.current = defaultDimensions;
                                        resizeCanvas(defaultDimensions);
                                    }
                                }} width={1200} className="pdf-page" />
                            </div>
                        </Document>
                    </div>
                </div>
                <div className="page-num-wrapper">
                    <div>{pageNumber} / {numPages}</div>
                    <a href={pdfFile} target="_blank" rel={"noreferrer"} className={"open-in-hd"}><div><ShareSVG /><div>HD</div></div></a>
                </div>
                <div className="nav-btn-wrapper next">
                    <button className={classNames(pageNumber === numPages && "disabled")} onClick={() => setPageNumber(pageNumber + 1)}><Arrow right/></button>
                </div>
            </div>
        </Modal>
    )
}

export default PdfModal;

function useResponsiveCanvas() {
    const canvasParentRef = useRef<HTMLDivElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const pageDefaultDimensionsRef = useRef({ width: 0, height: 0 });

    const resizeCanvas = useCallback((defaultDimensions: { width: number; height: number; }) => {
        const ratio = defaultDimensions.width/defaultDimensions.height;

        const canvas = canvasRef.current;
        const parent = canvasParentRef.current;
        if (canvas && parent) {
            const parentBox = parent.getBoundingClientRect();

            const desiredHeight = Math.min(defaultDimensions.height, parentBox.height);
            const desiredWidth = Math.min(desiredHeight*ratio, parentBox.width);
            console.log(`Resizing canvas to (${desiredWidth}px, ${desiredHeight}px)`)
            canvas.style.width = `${desiredWidth}px`;
            canvas.style.height = `${desiredWidth/ratio}px`;
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            resizeCanvas(pageDefaultDimensionsRef.current);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, [resizeCanvas]);

    return {
        canvasParentRef,
        canvasRef,
        pageDefaultDimensionsRef,
        resizeCanvas
    }
}
