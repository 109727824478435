import React from 'react';
import './index.css';
import classNames from "classnames";

export interface InfoCalloutProps {
  label: React.ReactNode;
  className?: string;
}

const InfoCallout: React.FC<InfoCalloutProps> = props => {
  return (
    <div className={classNames("info-callout", props.className)}>
      <label>{props.label}</label>
      <div className={"value"}>{props.children}</div>
    </div>
  );
}

export default InfoCallout;
