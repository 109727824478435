import React from "react";
import { Scene } from "./scene";

const SceneCanvas: React.FC<{
    SceneClass: { new(): Scene }
}> = props => {
    const sceneRef = React.useRef<Scene | null>(null);
    const containerRef = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        if (containerRef.current) {
            sceneRef.current = new props.SceneClass();
            sceneRef.current.init(containerRef.current);
            sceneRef.current.animate();
        }

        return () => {
            // clean up scene
            sceneRef.current?.dispose();
            sceneRef.current = null;
        };
    }, []);

    return <div style={{
        height: '100%'
    }} ref={containerRef}/>
};

export default SceneCanvas;