import React, {HTMLProps} from 'react';
import "./link.css";
import LinkContext from './link-context';
import { Link as RouterLink } from 'react-router-dom';

interface LinkProps {
  to?: string;
  newTab?: string;
}

const Link: React.FC<LinkProps> = props => {
  const [hovered, setHovered] = React.useState(false);
  const { glowColor, hoverOverLink } = React.useContext(LinkContext);

  const aProps = {
    style: {
      textShadow: `0 0 30px ${hovered ? glowColor : 'transparent'}, 0 0 15px ${hovered ? glowColor : 'transparent'}`
    },
    onMouseEnter: () => {
      setHovered(true);
      hoverOverLink(props.to ?? null);
    },
    onMouseLeave: () => setHovered(false)
  }

  return (
    <div>
      { props.to ?
      <RouterLink to={props.to} className="link"
        {...aProps}
      >
          {props.children}
      </RouterLink>
        : props.newTab ?
          <a href={props.newTab} className="link" target="_blank" rel="noopener noreferrer" {...aProps}>
            {props.children}
          </a> : null }
    </div>
  );
}

export default Link;
