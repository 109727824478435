import AstroHUDPage, {AstroHUDInfo} from "./pages/project/astrohud";
import ContrastVRPage, {ContrastVRInfo} from "./pages/project/contrastvr";
import VioPage, {VioInfo} from "./pages/project/vio";
import WimbledonPage, {WimbledonInfo} from "./pages/project/wimbledon";
import InteractionDesignPage, {InteractionDesignInfo} from "./pages/collection/interaction-design";
import PhysicalComputingPage, {PhysicalComputingInfo} from "./pages/collection/physical-computing";

const Directory = {
  projects: [
    [AstroHUDInfo, AstroHUDPage],
    [ContrastVRInfo, ContrastVRPage],
    [VioInfo, VioPage],
    [WimbledonInfo, WimbledonPage]
  ],
  collections: [
   [InteractionDesignInfo, InteractionDesignPage],
    [PhysicalComputingInfo, PhysicalComputingPage]
  ],
  all() { return [...this.projects, ...this.collections] }
} as const;

export default Directory;
