import React, { useCallback, useEffect, useRef } from "react";
import "./index.css";
import Modal from "../modal";

const videojs = require('video.js').default;

export interface VideoModalProps {
    youtubeUrl: string;
    title: string;

    isOpen: boolean;
    onClose(): void;
}

const VideoModal: React.FC<VideoModalProps> = props => {
    const videoNodeRef = useRef<HTMLVideoElement | null>(null);
    const playerRef = useRef<any>();

    const initPlayer = useCallback(() => {
        if (videoNodeRef.current && props.isOpen && !playerRef.current) {
            playerRef.current = videojs(videoNodeRef.current, {
                techOrder: ["youtube"],
                sources: [{ type: "video/youtube", src: props.youtubeUrl }],
                fill: true
            }, function onPlayerReady() {
                // console.log("PLAYER READY")
            })
        }
    }, [props.youtubeUrl, props.isOpen]);

    useEffect(() => {
        initPlayer();
    }, [initPlayer, props.isOpen])

    useEffect(() => {
        return () => {
            if (playerRef.current) {
                playerRef.current!.dispose();
                playerRef.current = null;
            }
        };
    })

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose} className="video-modal">
            <div className="flex items-center h-full max-h-full">
                <div className="relative py-24 flex-grow flex-shrink h-full max-h-full">
                    <div className="h-full w-full max-h-full max-w-full">
                        <video
                            ref={videoNode => {
                                videoNodeRef.current = videoNode;
                                initPlayer();
                            }}
                            className="video-js vjs-default-skin"
                            controls
                            autoPlay
                        >
                        </video>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default VideoModal;
