import React from 'react';
import "./index.css";
import Arrow from "../../../arrow";
import classNames from "classnames";
import Tippy from '@tippyjs/react/headless';
import HeroImage from "../../../hero-img";
import {ShowcaseInfo} from "../../type";
import Link from "../../../menu/link";
import {randomColor} from "../../../colors";

export interface NavArrowProps {
  left?: boolean;
  right?: boolean;
  info: ShowcaseInfo;
  className?: string;
}

const NavArrow: React.FC<NavArrowProps> = props => {
  const direction = props.left ? 'left' : 'right';

  const [glowColor, setGlowColor] = React.useState(randomColor());
  const setRandomGlowColor = () => setGlowColor(randomColor())

  return (
    <Tippy
      placement={direction === 'left' ? 'right' : 'left'}
      render={attrs => (
        <div className={classNames("nav-arrow-tooltip", direction)} tabIndex={-1} {...attrs}>
          <HeroImage src={props.info.heroImage} style={{
            boxShadow: `0px 0px 40px 5px  ${glowColor}`
          }} />
          <label>{props.info.title}</label>
        </div>
      )}
      onHidden={() => setRandomGlowColor()}
    >
      <span tabIndex={0}>
        <Link to={props.info.slug}><Arrow left={props.left} right={props.right} className={classNames("nav-arrow", props.className)} /></Link>
      </span>
    </Tippy>
  );
};

export default NavArrow;
