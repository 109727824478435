import React from 'react';
import './index.css';
import {ReactComponent as LinkedInIcon} from "./linkedin-icon.svg";
import {ReactComponent as MailIcon} from "./mail-icon.svg";
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import SceneCanvas from '../../components/3d-scene/canvas';
import AboutHandsScene from './about-hands-scene';
import { useHistory } from "react-router-dom";
import Arrow from "../../components/arrow";
import Header from "../../components/header";
import Footer from '../../components/footer';

const ContactPage: React.FC = () => {
  const history = useHistory();

  React.useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === "ArrowLeft") {
        history.push("/");
      }
    }

    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    }
  }, []);

  return (
    <div className="about-page">
      <div className="scene-bg">
        <SceneCanvas SceneClass={AboutHandsScene} />
      </div>
      <div className="page">
        <Header />
        <div className="content">
          <div className="about-title">About</div>
          <div className="about-description">
            I’m a designer and software engineer with a passion for human-computer interaction and innovative product
            development. I have experience working in interdisciplinary roles combining engineering, design, and
            research. My range of skills can take technology products from concept to completion at any step in the
            process–from plan to polish.
            <br/><br/>
            Interested in chatting or working together?
            Find me here.
          </div>
          <div className="social-links">
            <a className="social-link" href="https://www.linkedin.com/in/nicolettetovstashy/">
              <span className="social-icon"><LinkedInIcon/></span> /nicolettetovstashy
            </a>
            <a className="social-link" href="mailto: me@nicolette.io">
              <span className="social-icon"><MailIcon/></span> me@nicolette.io
            </a>
          </div>
        </div>
        <div className="flex-grow flex-shrink" />
        <Footer/>
      </div>
    </div>
  );
}

export default ContactPage;
