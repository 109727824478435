import React from 'react';
import './index.css';
import classNames from "classnames";
import InfoCallout from "../../info-callout";
import ShowcaseSubtitle from '../subtitle';

interface ShowcaseDescriptionProps {
  date?: React.ReactNode;
  subtitle?: React.ReactNode;
  info?: Array<{
    title: string;
    items: Array<string | {
      label: string;
      alt: string; // could be optional if we add other keys
    }>;
  }>;
  hideInfoOnMobile?: boolean;
}

const ShowcaseDescription: React.FC<ShowcaseDescriptionProps> = props => {
  return (
    <section className={classNames("showcase-description")}>
      {props.date && <div className={"date"}>{props.date}</div>}
      <ShowcaseSubtitle>{props.subtitle}</ShowcaseSubtitle>
      <div className="content">
        <div>{props.children}</div>
        <div className={classNames("info-callouts", props.hideInfoOnMobile && "hide-on-mobile")}>
          {props.info?.map(infoItem => (
            <InfoCallout key={infoItem.title} label={infoItem.title}>
              {infoItem.items.map(subItem => typeof subItem === "string" ? (
                <div key={subItem}>{subItem}</div>
              ) : (
                <div key={subItem.label} title={subItem.alt}>{subItem.label}</div>
              ))}
            </InfoCallout>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ShowcaseDescription;
