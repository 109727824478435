import React from "react";
import Tippy from '@tippyjs/react/headless';
import {followCursor} from 'tippy.js';
import { ShowcaseInfo } from "../showcase/type";
import { randomColor } from "../colors";
import HeroImage from "../hero-img";
import "./index.css";
import classNames from "classnames";

interface FeaturedShowcaseTooltipProps {
    info: ShowcaseInfo;
    followCursor?: boolean;
    hideLabel?: boolean;
    visibleOnSmallScreens?: boolean;
}

const FeaturedShowcaseTooltip: React.FC<FeaturedShowcaseTooltipProps> = props => {
    const [glowColor, setGlowColor] = React.useState(randomColor());
    const setRandomGlowColor = () => setGlowColor(randomColor()!)

    return (
        <Tippy
            render={attrs => (
                <div tabIndex={-1} {...attrs} className={classNames("featured-showcase-tooltip", !props.visibleOnSmallScreens && "hidden lg:block")}>
                    <HeroImage src={props.info.heroImage} style={{
                        boxShadow: `0px 0px 40px 5px  ${glowColor}`
                    }} />
                    {!props.hideLabel && <label>{props.info.title}</label>}
                </div>
            )}
            followCursor={props.followCursor}
            plugins={[followCursor]}
            onHidden={() => setRandomGlowColor()}
        >
            <span>{props.children}</span>
        </Tippy>
    )
}

export default FeaturedShowcaseTooltip;