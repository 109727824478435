import classNames from "classnames";
import React, { useState } from "react";
import VideoModal from "../video-modal";
import { ReactComponent as PlayIcon } from "./play.svg";

export interface VideoStillProps {
    className?: string;
    youtubeUrl: string;
    src: string;
    alt: string;
}

const VideoStill: React.FC<VideoStillProps> = props => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    return (
        <>
            <VideoModal 
                youtubeUrl={props.youtubeUrl}
                title={props.alt}
                isOpen={modalIsOpen} 
                onClose={() => setModalIsOpen(false)}    
            />
            <span className={classNames("inline-block cursor-pointer group transform transition-transform hover:scale-95", props.className)} onClick={() => setModalIsOpen(true)}>
                <div className={"relative"}>
                    <img 
                        className={"border border-white"}
                        src={props.src} 
                        alt={props.alt}
                    />
                    <div className={"absolute inset-0 flex justify-center items-center"}>
                        <span className={"filter drop-shadow rounded-full h-28 w-28 flex items-center justify-center bg-[rgba(0,0,0,0.6)] backdrop-filter backdrop-blur border-[1.5px] border-white transform transition-transform group-hover:scale-110"}>
                            <PlayIcon />
                        </span>
                    </div>
                </div>
            </span>
        </>
    )
}

export default VideoStill;