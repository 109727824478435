import React from 'react';
import ProjectTemplate from "../../../components/showcase/project/template";
import ShowcaseDescription from "../../../components/showcase/description";
import {ShowcaseInfo} from "../../../components/showcase/type";
import PdfImage from '../../../components/pdf-img';
import { HOSTED_FILE_ROOT } from '../../../files';

const ASTROHUD_FILE_ROOT = HOSTED_FILE_ROOT + "/astrohud";

export const AstroHUDInfo: ShowcaseInfo = {
  title: "AstroHUD",
  heroImage: ASTROHUD_FILE_ROOT + "/hero.jpg",
  slug: '/project/astrohud',
  date: 'Fall 2020 – Spring 2021'
}

const AstroHUDPage: React.FC = props => {
  return (
    <ProjectTemplate info={AstroHUDInfo}>
      <ShowcaseDescription
        date={AstroHUDInfo.date}
        subtitle={"The AstroHUD is a prototype AR display for astronauts."}
        info={[{
            title: "Skills",
            items: ["Interaction", "Prototyping", "Research"]
          }, {
            title: "Tools",
            items: ["Figma", "HoloLens 2", "Unity"]
          }, {
            title: "Team",
            items: ["Joseph Annis", "Teo Boley", "Riha Khan"]
          }]}
      >
        The next generation spacesuit has the potential to reimagine the astronaut workflow.
        By including a mixed reality heads-up display in the helmet, astronauts can leverage an integrated information
        network to enhance navigation, task completion, and communication. This display can also expose critical
        telemetry, and passively reduce the stress and mental load of working in space.
        <br/><br/>
        In this project, we research mission requirements, astronaut needs, and design guidelines to synthesize our
        interpretation of such a heads-up display. We present this synthesis in an annotated user interface and
        interaction spec, then prototype and validate the display using the HoloLens 2.
      </ShowcaseDescription>
      <section>
        <h3>Gigamap</h3>
        <div className={"grid grid-cols-6 gap-2 lg:gap-4"}>
          <a className={"col-span-6 border border-white cursor-pointer transform transition-transform hover:scale-95"} href={ASTROHUD_FILE_ROOT + "/poster_big.jpg"} target="_blank" rel="noreferrer">
            <img
              src={ASTROHUD_FILE_ROOT + "/poster.jpg"}
              alt={"Informational poster with the AstroHUD interface overlayed on renders of an astronaut and sample lunar traverse."}
            />
          </a>
          <img
            className={"col-span-2"}
            src={ASTROHUD_FILE_ROOT + "/poster_closeup_1.jpg"}
            alt={"Closeup of astronaut."}
          />
          <img
            className={"col-span-2"}
            src={ASTROHUD_FILE_ROOT + "/poster_closeup_2.jpg"}
            alt={"Closeup of science sampling requirements and sample interface views."}
          />
          <img
            className={"col-span-2"}
            src={ASTROHUD_FILE_ROOT + "/poster_closeup_3.jpg"}
            alt={"Closeup of navigation requirements and sample interface views."}
          />
          <img
            className={"col-span-3"}
            src={ASTROHUD_FILE_ROOT + "/poster_closeup_4.jpg"}
            alt={"Closeup of auxiliary feature requirements and sample interface views."}
          />
          <img
            className={"col-span-3"}
            src={ASTROHUD_FILE_ROOT + "/poster_closeup_5.jpg"}
            alt={"Closeup of sampling site from lunar traverse render."}
          />
        </div>
      </section>
      <section>
        <h3>Interface Spec</h3>
        <div className={"grid grid-cols-4 gap-2 lg:gap-4"}>
          <PdfImage
            className={"col-span-4"}
            pdfSlug={"AstroHUD UI Spec"}
            src={ASTROHUD_FILE_ROOT + "/spec.jpg"}
            alt={"Title slide of user interface spec packet."}
          />
          <img
            className={"col-span-1"}
            src={ASTROHUD_FILE_ROOT + "/spec_closeup_1.jpg"}
            alt={"Full-format astronaut card overlayed on the helmet of an astronaut."}
          />
          <img
            className={"col-span-1"}
            src={ASTROHUD_FILE_ROOT + "/spec_closeup_2.jpg"}
            alt={"Tool annotation on a telescoping hammer."}
          />
          <img
            className={"col-span-1"}
            src={ASTROHUD_FILE_ROOT + "/spec_closeup_3.jpg"}
            alt={"Ping outline of a tool trunk visible through a rock formation."}
          />
          <img
            className={"col-span-1"}
            src={ASTROHUD_FILE_ROOT + "/spec_closeup_4.jpg"}
            alt={"Video recording frame over an aerial view of a lunar roving vehicle."}
          />
          <img
            className={"col-span-1"}
            src={ASTROHUD_FILE_ROOT + "/spec_closeup_5.jpg"}
            alt={"Three site micronavigation points set in a cavernous science sampling site."}
          />
          <img
            className={"col-span-1"}
            src={ASTROHUD_FILE_ROOT + "/spec_closeup_6.jpg"}
            alt={"Wayfinding path, landmark annotations, and destination waypoints set over a flat plain with mountains on the horizon."}
          />
          <img
            className={"col-span-1"}
            src={ASTROHUD_FILE_ROOT + "/spec_closeup_7.jpg"}
            alt={"Alert vignette and symbols as they appear in the central view, with the bottom biometrics panel visible for reference."}
          />
          <img
            className={"col-span-1"}
            src={ASTROHUD_FILE_ROOT + "/spec_closeup_8.jpg"}
            alt={"Ten running astronauts outlined and annotated with minimized astronaut cards."}
          />
          <img
            className={"col-span-2"}
            src={ASTROHUD_FILE_ROOT + "/spec_closeup_9.jpg"}
            alt={"Fully annotated scene of lava tube opening, astronaut, and tools. Features destination waypoint and geographical annotation on lava tube, minimized astronaut card and outline on astronaut, ping waypoint on camera, and comms panel."}
          />
          <img
            className={"col-span-2"}
            src={ASTROHUD_FILE_ROOT + "/spec_closeup_10.jpg"}
            alt={"Fully annotated scene of science sampling site with one astronaut. Features tool demonstration animation, minimized astronaut card and outline on astronaut, ping waypoint on tool trunk, and comms panel."}
          />
          <img
            className={"col-span-2 col-start-2"}
            src={ASTROHUD_FILE_ROOT + "/spec_closeup_11.jpg"}
            alt={"Highlighted central field of view, contextualized with top map and bottom biometrics panel partially visible outside of highlighted area."}
          />
        </div>
      </section>
    </ProjectTemplate>
  );
}

export default AstroHUDPage;
