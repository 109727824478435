import classNames from "classnames";
import React from "react";
import CollectionItem, { CollectionItemContent } from "../item";

interface CollectionItemsProps {
    children: Array<CollectionItemContent>;
    className?: string;
}

const CollectionItems: React.FC<CollectionItemsProps> = props => {
    return (
        <ul className={classNames("border-t border-b border-white divide-y divide-white", props.className)}>
            {props.children.map((child, i) => (
                <CollectionItem key={i} content={child} />
            ))}
        </ul>
    );
}

export default CollectionItems;