import React from "react";
import NavArrow from "./nav-arrow";
import Directory from "../../../directory";
import { ShowcaseInfo } from "../type";

interface NavProps {
    info: ShowcaseInfo;
}

const Nav: React.FC<NavProps> = props => {
    const prev = Directory.all()[Directory.all().findIndex(([routeInfo]) => routeInfo.slug === props.info.slug) - 1];
    const next = Directory.all()[Directory.all().findIndex(([routeInfo]) => routeInfo.slug === props.info.slug) + 1];

    return (
        <nav>
            {prev ? <NavArrow left info={prev[0]} /> : <span />}
            {next ? <NavArrow right info={next[0]} /> : <span />}
        </nav>
    )
} 

export default Nav;