import React from 'react';
import classNames from "classnames";
import "./index.css"

export interface HeroImageProps {
  src: React.ReactNode
  className?: string;
  style?: React.CSSProperties;
}

const HeroImage: React.FC<HeroImageProps> = props => {
  return (
    <div className={classNames("hero-image", props.className)} style={props.style}>
      {typeof props.src === "string" ? <img className={"def"} alt={"Hero"} src={props.src} /> : props.src}
    </div>
  );
};

export default HeroImage;
