import React from "react";
import ReactModal from 'react-modal';
import {ReactComponent as Close} from "./close.svg";
import classNames from "classnames";
import "./index.css";
import { useModalScrollLock } from "../../hooks/modal";

export interface ModalProps {
    isOpen: boolean;
    onClose(): void;
    className?: string;
}

const Modal: React.FC<ModalProps> = props => {
    useModalScrollLock(props.isOpen);

    return (
        <ReactModal appElement={document.querySelector('#root')!} parentSelector={() => document.querySelector('#modal-root')! as HTMLElement} isOpen={props.isOpen} onRequestClose={props.onClose} className={classNames("modal", props.className)} overlayClassName="modal-overlay">
            <div className="modal-area">
                { props.children }
                <button className="close-btn" onClick={props.onClose}><Close width={40} height={40}/></button>
            </div>
        </ReactModal>
    )
}

export default Modal;
