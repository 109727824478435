import {useEffect} from 'react';

export function useModalScrollLock(active: boolean) {
    // When active changes...
    useEffect(() => {
        // if active, add the modal-scroll-lock class to body
        if (active) {
            document.body.classList.add("modal-scroll-lock");
        } else {
            document.body.classList.remove("modal-scroll-lock");
        }
        
        return () => {
            document.body.classList.remove("modal-scroll-lock");
        }
    }, [active]);
}