import React from 'react';
import './index.css';
import Menu from '../../components/menu';
import classNames from 'classnames';
import SceneCanvas from '../../components/3d-scene/canvas';
import HomeHandsScene from './home-hands-scene';
import { ReactComponent as MenuIcon } from "../../components/menu/menu.svg";
import Footer from '../../components/footer';

const HomePage: React.FC = () => {
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);

  React.useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === "ArrowRight") {
        setMenuIsOpen(true);
      } else if (e.key === "ArrowLeft") {
        setMenuIsOpen(false);
      }
    }

    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    }
  }, []);

  return (
    <div className="home-page">
      <Menu isOpen={menuIsOpen} onClose={() => setMenuIsOpen(false)} />
      <div className="scene-bg">
        <SceneCanvas SceneClass={HomeHandsScene} />
      </div>
      <div className="page">
        <div className={classNames("menu-icon", menuIsOpen && "menu-open")} onClick={() => setMenuIsOpen(true)}><MenuIcon /></div>
        <div className="content">
          <h1 className="title">Nicolette<br/>Tovstashy</h1>
          <div className="subtitle">Design | Human Factors | Engineering</div>
        </div>
        <div><Footer /></div>
      </div>
    </div>
  );
}

export default HomePage;
