import classNames from "classnames";
import React, { useState } from "react";
import PdfModal from "../pdf-modal";

export interface PdfImageProps {
    className?: string;
    pdfSlug: string;
    src: string;
    alt: string;
}

const PdfImage: React.FC<PdfImageProps> = props => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    return (
        <>
            <PdfModal 
                slug={props.pdfSlug}
                isOpen={modalIsOpen} 
                onClose={() => setModalIsOpen(false)}    
            />
            <img 
                className={classNames("border border-white cursor-pointer transform transition-transform hover:scale-95", props.className)}
                src={props.src} 
                alt={props.alt}
                onClick={() => setModalIsOpen(true)}
            />
        </>
    )
}

export default PdfImage;