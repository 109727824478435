import React from "react";

interface ILinkContext {
    glowColor: string;
    hoveredLink: string | null;
    hoverOverLink(slug: string | null): void;
}

const LinkContext = React.createContext<ILinkContext>({
    glowColor: "white",
    hoveredLink: null,
    hoverOverLink: () => {}
});

export default LinkContext;
