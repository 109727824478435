import React from 'react';
import InfoCallout from '../../../components/info-callout';
import CollectionItems from '../../../components/showcase/collection/items';
import CollectionTemplate from '../../../components/showcase/collection/template';
import {ShowcaseInfo} from "../../../components/showcase/type";
import VideoStill from '../../../components/video-still';
import ShowcaseDescription from '../../../components/showcase/description';
import { HOSTED_FILE_ROOT } from '../../../files';

const PHYSICAL_COMPUTING_FILE_ROOT = HOSTED_FILE_ROOT + "/physical_computing";

export const PhysicalComputingInfo: ShowcaseInfo = {
  title: "Physical Computing",
  heroImage: PHYSICAL_COMPUTING_FILE_ROOT + "/hero.jpg",
  slug: '/collection/physical-computing'
}

const PhysicalComputingPage: React.FC = props => {
  return (
    <CollectionTemplate info={PhysicalComputingInfo}>
      <ShowcaseDescription
        subtitle={"Creative engineering projects that combine electronics with form."}
        info={[{
            title: "Skills",
            items: ["Electronics", "Prototyping", "Sculpture"]
          }]}
        hideInfoOnMobile
      >
        This collection features physical computing prototypes. Projects evolve in complexity 
        from papercraft to microcontrollers, and require working with diverse tools, materials, 
        and manufacturing processes.
      </ShowcaseDescription>
      <section>
          <CollectionItems>
            {[
              {
                date: "Spring 2021",
                title: "Happy Plant",
                body: (<>
                  <div className={"flex flex-col md:flex-row md:justify-between"}>
                    <p className={"md:mr-28 lg:mr-44 ml-gutter"}>
                    Happy Plant is an interactive tabletop ornament that pays homage to 
                    being a plant parent. This project is crafted with origami and sticker circuits.  
                    </p>
                    <InfoCallout label={"Materials"} className={"mt-6 md:mt-0"}>
                      <div>Circuit Stickers</div>
                      <div>Paper</div>
                    </InfoCallout>
                  </div>
                  <VideoStill className="mt-14 lg:mt-24" src={PHYSICAL_COMPUTING_FILE_ROOT + "/happy_plant.jpg"} youtubeUrl={"https://youtu.be/R-E5oBskUzk"} alt={"Happy plant."} />
                </>)
              },
              {
                date: "Spring 2021",
                title: "Supermassive Black Hole",
                body: (<>
                  <div className={"flex flex-col md:flex-row md:justify-between"}>
                    <p className={"md:mr-28 lg:mr-44 ml-gutter"}>
                    Supermassive Black Hole is a rhythm game for the Muse song by the same name. It features
                    an ambient music visualizer that changes with the progression of the song.
                    <br></br>
                    <br></br>                    
                    The game and visualizer are programmed using Processing, and the Micro:bit is used 
                    for physical input from the player. 
                    </p>
                    <div className={"flex flex-row md:flex-col mt-6 md:mt-0"}>
                      <InfoCallout label={"Materials"} className="md:mb-8 w-1/3">
                        <div>Cardboard</div>
                        <div>Micro:bit</div>
                      </InfoCallout>
                      <InfoCallout label={"Github"} className="lg:mb-8 w-1/3">
                        <a href="https://github.com/nicolettemaria/microbit-rhythm-game">Link</a>
                      </InfoCallout>
                    </div>
                  </div>
                  <VideoStill className="mt-14 lg:mt-24" src={PHYSICAL_COMPUTING_FILE_ROOT + "/supermassive.jpg"} youtubeUrl={"https://www.youtube.com/watch?v=FEGdr21w9B0"} alt={"Supermassive black hole."} />
                </>)
              },
              {
                date: "Spring 2021",
                title: "Ambient Coral",
                body: (<>
                  <div className={"flex flex-col md:flex-row md:justify-between"}>
                    <p className={"md:mr-28 lg:mr-44 ml-gutter"}>
                    Ambient Coral is an exhibit design prototype for the Boston Museum of Science. 
                    This interactive sculpture represents the coral reefs of Hawaii. Using ocean data 
                    collected by the NOAA since 1985, the sculpture leverages light to reflect the 
                    health of the coral as ocean temperatures fluctuate. In its full-scale form, the 
                    sculpture is intended to be photographed with. The structure is sculpted using 
                    wood, chicken wire, and recycled plastic bags; the control panel uses an Arduino; 
                    and the lights are programmed using Kotlin, Processing, and the Philips Hue API. 
                    </p>
                    <div className={"flex flex-row md:flex-col mt-6 md:mt-0"}>
                      <InfoCallout label={"Materials"} className="md:mb-8 w-1/3">
                        <div> Arduino </div>
                        <div> Hardware </div>
                        <div> Philips Hue </div>
                      </InfoCallout>
                      <InfoCallout label={"Github"} className="lg:mb-8 w-1/3">
                        <a href="https://github.com/nicolettemaria/ambient-coral">Link</a>
                      </InfoCallout>
                    </div>
                  </div>
                  <VideoStill className="mt-14 lg:mt-24" src={PHYSICAL_COMPUTING_FILE_ROOT + "/coral.jpg"} youtubeUrl={"https://youtu.be/r5e76zNuTYI"} alt={"Ambient coral."} />
                </>)
              },
              {
                date: "Spring 2021",
                title: "Alternation",
                body: (<>
                  <div className={"flex flex-col md:flex-row md:justify-between"}>
                    <p className={"md:mr-28 lg:mr-44 ml-gutter"}>
                      Alternation is an interactive sculpture evocative of entropy. It features two 
                      opposing forces–one actor of order, and one actor of disorder. The enclosure 
                      is constructed using laser-cut acrylic and acrylic weld, wooden supports, 
                      hinges, and mesh netting. The servo motors and squirrel cage motor fan are 
                      controlled with an Arduino.
                    </p>
                    <div className={"mt-6 md:mt-0"}>
                      <InfoCallout label={"Materials"} className="lg:mb-8">
                        <div> Acrylic </div>
                        <div> Arduino </div>
                      </InfoCallout>
                    </div>
                  </div>
                  <VideoStill className="mt-14 lg:mt-24" src={PHYSICAL_COMPUTING_FILE_ROOT + "/alternation.jpg"} youtubeUrl={"https://youtu.be/HkjRt60O7M0"} alt={"Alternation."} />
                </>)
              }
            ]}
          </CollectionItems>
      </section>
    </CollectionTemplate>
  );
}

export default PhysicalComputingPage;
