import React from 'react';
import classNames from "classnames";
import "./index.css";
import {ReactComponent as ArrowIcon} from "./arrow.svg";

export interface ArrowProps {
  left?: boolean;
  right?: boolean;
  className?: string;
}

const Arrow: React.FC<ArrowProps> = props => {
  const direction = props.left ? 'left' : 'right';

  return (
    <span className={classNames("arrow", direction, props.className)}><ArrowIcon /></span>
  );
};

export default Arrow;
